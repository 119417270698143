import React, { Fragment } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import SearchPage from "./art/SearchPage";
import HomePage from "./art/HomePage";
import ScrollToTop from "../ui/common/ScrollToTop";
import CollectionPage from "./art/CollectionPage";
import PicturePage from "./art/PicturePage";

export default function PosterRouter() {
    return (
        <Router>
            <Fragment>
                {
                    (!process?.env?.NODE_ENV || process?.env?.NODE_ENV === 'development') ?
                        (<nav>
                            <Link to="/p/art/favorites">Favoritos</Link> |
                            <Link to="/p/art/gallery/anime">lista llamada anime</Link> |
                            <Link to="/p/art/search?q=auto">buscar auto</Link>
                        </nav>)
                        :
                        (<Fragment />)
                }
                <ScrollToTop />
                <Routes>
                    {/* <Route path="/p/art/favorites" element={<FavoritesPage />} /> */}
                    <Route path="/p/art/search" element={<SearchPage origin={"art"} />} />
                    <Route path="/p/art/gallery/:name" element={<CollectionPage />} />
                    {/* <Route path="/p/art/section/:slug" element={<SectionPage />} />
                    <Route path="/p/art/section/:slug/:id" element={<SectionPage />} />  */}
                    <Route path="/p/art/view/:gallery/:id_image" element={<PicturePage origin={"art"} />} />
                    <Route path="/p/art" element={<HomePage />} />
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </Fragment>
        </Router>
    );
};