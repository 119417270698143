import React, { useState, useEffect } from "react";
import { showLoading, hideLoading, gotoTop } from "../../util/SwitchLoading";
import jQuery from 'jquery';
import ConfigSite from "../../ConfigSite";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import axios from "axios";
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
const u = jQuery.noConflict();

const config = new ConfigSite();
const API = 'site/v1/art/';

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default function CollectionPage() {

    let [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    showLoading();
    hideAll();
    u('body').addClass('collection');
    u('body').removeClass('home');
    u('body').removeClass('search');
    u('body').removeClass('producto');
    u('body').removeClass('favorites');
    // TODO ??? u('#editorSection').show();
    u('section.top').show();
    u('#info').show();

    useEffect(() => {
        showLoading();
        setIsLoading(true);
        axios.get(config.global.pictureApi.urlBase + API + params.name)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }
                setIsLoading(false);
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [params.name]);

    if (isLoading || data.length === 0) {
        return (<React.Fragment></React.Fragment>);
    }

    gotoTop();
    hideLoading();

    return (
        <section className={"template_align_right"} >
            <h3>{data.gallery.name}</h3>
            <div className={'container wp-home-sliders'} >
                <div className={'home-category-slider home-category-slider-' + data.gallery.id} >
                    <ImageList variant="masonry" cols={3} gap={10}>
                        {data.images.map((item) => (
                            <Link to={"/p/art/view/" + params.name + "/" + item.id} key={"link" + item.id}>

                                <ImageListItem key={item.id}>
                                    <img
                                        srcSet={`${item.cdnUrl}`}
                                        src={`${item.cdnUrl}`}
                                        alt={item.description}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            </Link>
                        ))}
                    </ImageList>
                </div>
            </div>

        </section>
    );
}
