class PreviewStore {
    static asyncStore(preview, id) {
        if (window.FusionnImagePreviews===undefined) {
            window.FusionnImagePreviews = [];
        }
        window.FusionnImagePreviews[id] = preview;
        window.refreshCart();

        fetch('/content/preview', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({ preview : preview })
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data.status === 'ok') {
                if (window.FUSIONN.cart !== undefined && window.FUSIONN.cart.items.length > 0) {
                    for (let i=0; i<window.FUSIONN.cart.items.length; i++ ) {
                        if (window.FUSIONN.cart.items[i].id === id) {
                            window.FUSIONN.cart.items[i].attributes.previewFile = data.previewFile;
                            window.FUSIONN.updateCart("fotoc.addToCart");
                            break;
                        }
                    }
                    window.refreshCart();
                }
            }
        });
    }

    static buildPreviews(items) {
        try {
            window.Editor.getFramesPreviews().then((value) => {
                let previews = value;
                let list = [];
                for(let i=0; i<previews.length; i++) {
                    PreviewStore.asyncStore(previews[i],items[i].id);
                }
            });
        }
        catch(error) {
            console.error(error);
        }
    }
}
export default PreviewStore;