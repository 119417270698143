import React, { Component } from "react";
import {showLoading, gotoTop} from "../../util/SwitchLoading";
import jQuery from 'jquery';
import HomeSection from "./HomeSection";
const u = jQuery.noConflict();


const hideAll = () => {
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default class HomePage extends Component {

    constructor(props) {
        super(props);

        showLoading();
        hideAll();
        u('body').addClass('home');
        u('body').removeClass('producto');
        u('section.top').show();
        u('#info').show();
    }

    render() {
        gotoTop();
        return (
            <section className="main-content container">
                <HomeSection/>
            </section>
        );
    }
}