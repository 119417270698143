import React, { Fragment } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import PosterPage from "./poster/PosterPage";
import HomePage from "./poster/HomePage";
import ScrollToTop from "../ui/common/ScrollToTop";
import CollectionPage from "./poster/CollectionPage";
import FavoritesPage from "./poster/FavoritesPage";

export default function PosterRouter() {
    return (
        <Router>
            <Fragment>
                {
                    (!process?.env?.NODE_ENV || process?.env?.NODE_ENV === 'development') ?
                        (<nav>
                            <Link to="/p/poster/favorites">Favoritos</Link> |
                            <Link to="/p/poster/collection/random">lista llamada random</Link> |
                            <Link to="/p/poster/show/tv/75983/mostafa-afzali">pagina no encontrada </Link> |
                        </nav>)
                        :
                        (<Fragment />)
                }
                <ScrollToTop />
                <Routes>
                    <Route path="/p/poster/favorites" element={<FavoritesPage />} />
                    <Route path="/p/poster/view/poster/:id_image/:title" element={<PosterPage origin={"poster"} />} />
                    <Route path="/p/poster/view/poster/:id_image" element={<PosterPage origin={"poster"} />} />
                    <Route path="/p/poster/show/:media_type/:id_media/*" element={<PosterPage origin={"movie"} />} />
                    <Route path="/p/poster/collection/:name" element={<CollectionPage />} />
                    <Route path="/p/poster" element={<HomePage />} />
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </Fragment>
        </Router>
    );
};