import React, { Component } from 'react';
import axios from 'axios';
import { showLoading, hideLoading } from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainers from "../../ui/fanatico/home/SimpleGalleryContainers";

const config = new ConfigSite();
const API = 'es/cinematheque/home';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

export default class HomeSection extends Component {

    constructor(props) {
        super(props);

        showLoading();

        this.state = {
            home: [
            ],
            isLoading: true,
        };
    }

    componentDidMount() {
        showLoading();
        this.setState({ isLoading: true });


        this.setState({ isLoading: true });
        axios.get(config.global.mediaApi.urlBase + API)
            .then((response) => {
                // handle success
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }

                // Examine the text in the response
                this.setState({ home: response.data, isLoading: false });
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    }

    render() {
        const { home, isLoading } = this.state;
        if (isLoading) {
            return ("");
        }
        gotoTop();
        hideLoading();

        return (
            <SimpleGalleryContainers sliders={home} />
        );
    }
}