import React, { useState, useEffect } from "react";
import jQuery from 'jquery';
import ConfigSite from "../../ConfigSite";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MasonryInfiniteScroller from 'react-masonry-infinite';
import StockImage from "../../ui/art/art/StockImage";

const u = jQuery.noConflict();

const config = new ConfigSite();
const API = 'picture-store/site/v1/art/search?';

export const masonryOptions = {
    itemSelector: '.art-grid-item',
    resize: true
};

const hideAll = () => {
    u('#top').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
    u('#editorSection').hide();
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SearchPage() {

    const query = useQuery();

    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [elements, setElements] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const loadData = () => {

        if (isLoading) {
            return;
        }

        setIsLoading(true);
        setPage(page + 1)

        axios.get(config.global.mediaApi.urlBase + API + new URLSearchParams({
            q: query.get("q"),
            p: page
        }))
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }

                setElements(elements.concat(response.data.data));
                setPage(response.data.page);
                setTotal(response.data.total_count);
                setIsLoading(false);
                setHasMore(false);

                if (response.data.page * 60 > response.data.total_count) {
                    setHasMore(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    hideAll();
    u('body').addClass('search');
    u('body').removeClass('producto');
    u('body').removeClass('collection');
    u('body').removeClass('favorites');
    u('body').removeClass('home');

    u('section.top').show();
    u('#info').show();

    useEffect(() => {
        loadData();
    }, [query.get("q")]);

    return (
        <section className={"template_align_right"} >
            <MasonryInfiniteScroller
                hasMore={hasMore}
                loadMore={loadData}
                pack={true}
                sizes={[{ columns: 1, gutter: 20 }, { mq: '1200px', columns: 2, gutter: 20 }]}
            >
                {
                    elements !== undefined && elements.length > 0 ?
                        elements.map((data, i) => <StockImage key={'agsii' + i} data={data} i={i} />) :
                        ""
                }
            </MasonryInfiniteScroller>
        </section>
    );
}
