import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import SearchOption from "./SearchOption";
import ConfigSite from "../../../ConfigSite";
import axios from "axios";

const config = new ConfigSite();
const API = 'es/cinematheque/search?';

const prepareOptions = (data) => {
    return data.results.filter((it) => (it.media_type && (it.media_type === "movie" || it.media_type === "tv"))).map((it) => {
        return {
            value: it.id,
            label: <SearchOption {...it} />
        }
    });
};

const loadOptions = (inputValue, callback) => {
    const query = inputValue;
    if (query.length >= 2) {
        window.lastTextSearch = query;
        axios.get(config.global.mediaApi.urlBase + API + new URLSearchParams({
            query: query,
            page: 1
        }))
            .then((response) => {
                let data = response.data;
                callback(prepareOptions(data));
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    } else {
        callback([]);
    }
}

export default class SearchForm extends React.Component  {

    constructor(props) {
        super(props);
        this.state = { inputValue: '' };
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue;
        this.setState({inputValue});
        return inputValue;
    };

    render() {
        return (
            <React.Fragment>
                <input type="hidden" value={this.state.inputValue}/>
                <AsyncSelect
                    className='search-header-container' classNamePrefix="search-header"
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onInputChange={this.handleInputChange}
                    placeholder={"Busca tu película o serie favorita"}
                    noOptionsMessage={() => { return "sin resultados"; }}
                />
            </React.Fragment>
        );
    }
}
