import React, { Component } from 'react';
import SimpleGalleryItem from "./SimpleGalleryItem";

export default class SimpleGalleryContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){

    }

    render() {
        if (!this.props.images || this.props.images.length === 0) {
            return ("");
        }

        return (
            <React.Fragment>
                <h3>{this.props.name}</h3>
                <div className={'container wp-home-sliders ' + (this.props.className ? this.props.className : '')} >
                    <div className={'home-category-slider home-category-slider-' + this.props.slug} >
                        {this.props.images.map( (it, i) => ( <SimpleGalleryItem key={'sgi'+i} item={it} /> ) ) }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
