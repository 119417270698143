class ConfigSite {

    constructor() {
        this.config = {};
        this.localStorage = (typeof (Storage) !== "undefined");
        this.sitePrices = window.sitePrices || {};
        this.global = window.globalConfig || {};
    }

    static getDefined(value, alt) {
        return (value === undefined || value === null) ? alt : value;
    }

    setItem(key, data) {
        try {
            data = JSON.stringify(data);
        } catch (e) {
            console.log(e);
        }
        if (this.localStorage) {
            window.localStorage.setItem(key, data);
        } else {
            this.config[key] = JSON.stringify(data);
        }
    }

    getItem(key) {
        let value;
        if (this.localStorage) {
            value = window.localStorage.getItem(key);
        } else {
            value = this.config[key];
        }
        try {
            value = JSON.parse(value);
        } catch (e) {
            //console.log(e);
        }
        return value;
    }

    getJSON(key) {
        let data = this.getItem(key);
        let json = {};
        try {
            json = JSON.parse(data);
        } catch (e) {
            //console.log(e);
        }
        return json;
    }

    getString(key) {
        return ConfigSite.getDefined(this.getItem(key), '');
    }

    getInteger(key) {
        let integer = ConfigSite.getDefined(this.getItem(key), 0);
        return parseInt(integer);
    }

    getFloat(key) {
        let float = ConfigSite.getDefined(this.getItem(key), 0);
        return parseFloat(float);
    }

}

export default ConfigSite;