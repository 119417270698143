import React, { useState, useEffect } from "react";
import axios from 'axios';
import { showLoading } from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Link } from "react-router-dom";

const config = new ConfigSite();
const API = 'site/v1/art/home';

export default function HomeSection() {

    const [home, setHome] = useState([]);

    useEffect(() => {
        showLoading();
        axios.get(config.global.pictureApi.urlBase + API)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }
                setHome(response.data);
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    }, []);

    if (home.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <ImageList variant="masonry" cols={3} gap={10}>
                {home.images.map((item) => (
                    <Link to={"/p/art/view/home/" + item.id} key={"link" + item.id}>
                        <ImageListItem key={item.id}>
                            <img
                                srcSet={`${item.cdnUrl}`}
                                src={`${item.cdnUrl}`}
                                alt={item.description}
                                loading="lazy"
                            />
                        </ImageListItem>
                    </Link>
                ))}
            </ImageList>
        </React.Fragment>
    );
}
